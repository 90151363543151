.projects-container {
  display: grid;
  place-items: center;
  background-color: #fdfaf6;
}

.portfolio {
  font-size: 40px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.projects {
  display: inline-grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-bottom: 100px;
}

.project img:hover {
  color: #e34427;
}

.projects a:hover, a:visited, a:link, a:active
{
    text-decoration: none;
}

.project {
  /*
  border: solid;
  border-width: 5px;
  border-color: #f2f3f4;*/
  padding: 10px 10px 10px 10px;
  text-align: left;
  transition: transform 0.2s;
  cursor: pointer;
}

.project h2 {
  color: #222222;
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 18px;
}

.project h3 {
  color: #222222;
  margin: 0px;
  font-size: 16px;
  margin-bottom: 5px;
}

.project h4 {
  color: rgb(128, 26, 0);
  font-size: 12px;
  margin-top: 0px;
  margin-bottom: 25px;
}

.project img {
  border: solid;
  border-width: 0.5px;
  object-fit: cover;
  padding: 10px;
  width: 330px;
  height: 215px;
  background: #f2f3f413;
}

.project a {
  color: black;
}

.project a:visited {
  color: black;
}

.project a:active {
  color: #e34427;
}

@media only screen and (max-width: 1170px) {
  .projects {
    display: inline-grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 850px) {
  .projects {
    display: inline-grid;
    grid-template-columns: 1fr;
  }

  .portfolio {
    font-size: 30px;
  }
}
