.fill {
  height: 100vh;
}

.intro {
  position: absolute; /* 2 */
  top: 40%; /* 3 */
  transform: translate(0, -25%); /* 4 */
  left: 10%;
}

.intro h1 h2 {
  color: #222222;
}

.intro h1 {
  font-size: 60px;
  margin: 0px;
}

.intro h2 {
  margin-top: 0px;
  margin-bottom: 12px;
}

.intro a {
  color: #e34427;
  margin-right: 10px;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
}

.intro a:hover {
  text-decoration: underline;
  text-decoration-thickness: 2px;
}

.blob1 {
  width: 350px;
  height: 325px;
  margin: 0px;
  position: absolute;
  top: 15%;
  right: 12.5%;
}

.blob2 {
  width: 350px;
  height: 370px;
  margin: 0px;
  position: absolute;
  top: 45%;
  right: 17.5%;
}

.blob3 {
  width: 150px;
  height: 150px;
  margin: 0px;
  position: absolute;
  top: 40%;
  right: 10%;
}

@media only screen and (max-width: 1170px) {
  .blob1 {
    width: 295px;
    height: 275px;
    margin: 0px;
    position: absolute;
    top: 20%;
    right: 7.5%;
  }

  .blob2 {
    width: 300px;
    height: 320px;
    margin: 0px;
    position: absolute;
    top: 45%;
    right: 12.5%;
  }

  .blob3 {
    width: 125px;
    height: 125px;
    margin: 0px;
    position: absolute;
    top: 40%;
    right: 7.5%;
  }
  /*
  .intro h1 {
    font-size: 40px;
  }

  .intro h2 {
    font-size: 20px;
  }
*/
  .fill {
    height: 100vh;
  }
}

@media only screen and (max-width: 850px) {
  .blobs {
    display: none;
  }

  .intro {
    position: relative;
    transform: none;
    left: auto;
    display: grid;
    place-items: center;
    justify-items: left;
    justify-content: center;
    margin-top: 60px;
  }

  .fill {
    height: 50px;
  }

  .intro h1 {
    font-size: 40px;
  }

  .intro h2 {
    font-size: 20px;
  }
}
